<template>
    <div>   
        <v-container grid-list-xl> 
            <v-layout align-center="" justify-center="">
                <v-col sm="12" cols="12"> 
                    <br>
                    <v-card > 
                        <mbs-page-tab 
                            :items="MyLinks"/> 
                        <mbs-item-manager
                            :add_vertical_lines="true"
                            :top_label="false"
                            :name_items="NAME_ITEMS"
                            :name_item="NAME_ITEM"
                            :value_items="VALUE_ITEMS"
                            :value_item="VALUE_ITEM" 
                            :field_items="DATA.FIELDS.computers"
                            :header_items="HEADER_ITEMS"
                            :header_items2="HEADER_ITEMS2"
                            :data_items="Computers"    
                            :item_data_include="{
                                device_type:DATA.ITEMS.COMPUTERS.value
                            }"    
                            :is_joined="true" 
                            :select_items="Selectors"   
                            :path_item="PATH_ITEM"   

                            :table_actions="table_actions" 
                            @SELECT_TABLE_ACTION="TABLE_ACTION"
                        />
                    </v-card>
                    <br><br>
                </v-col>  
            </v-layout>
        </v-container>  
    </div> 
</template>

<script>
    import DATA from '../../../../plugins/DATA'
    import {mapState} from "vuex"
    let PAGE_NAME = DATA.ITEMS.COMPUTERS.values    
    export default {
        name:PAGE_NAME, 
        data() {
            return {
                PAGE_LOAD:false,  
                PAGE_CURRENT:1,
                PAGE_SIZE:10,

                NAME_ITEMS:DATA.ITEMS.COMPUTERS.names,
                NAME_ITEM:DATA.ITEMS.COMPUTERS.name,
                VALUE_ITEMS:DATA.ITEMS.DEVICES.values,
                VALUE_ITEM:DATA.ITEMS.DEVICES.value,       
                table_actions:[
                    {icon:"add",type:"btn",action:"add_item",color:"secondary", outlined:false,  text:"Add Computer"},  
                ],
                DATA:DATA
            }
        },
        created() {
            try {
                this.MBS.events.$on('DIALOG_YES', this.DIALOG_YES);  
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'created',
                    page:PAGE_NAME, 
                }) 
            }
        },
        mounted() {
            try { 
                this.MBS.actions.header({
                    show:true,
                    type:'subToolbar', 
                    images:null,
                    color:'black',
                    height:"300",
                    opacity:'0.1'
                }) 
            } catch (error) {
                this.MBS.actions.error({
                    error:error,
                    from:'mounted',
                    page:PAGE_NAME, 
                }) 
            }
            
        },
        computed: { 
            ...mapState({
                loading:state=>state.load.loading,
                processes:state=>state.load.processes,
                responses:state=>state.load.responses,
            }),
            ...mapState({
                us: state=> state.users[(DATA.ITEMS.USERS.value).toUpperCase()],
                ud: state=> state.users[(DATA.ITEMS.USER_DETAILS.values).toUpperCase()],
            }),
            ...mapState({
                Devices: state=> state.items[(DATA.ITEMS.DEVICES.values).toUpperCase()],
                JoinedDevices: state=> state.join[(DATA.ITEMS.DEVICES.values).toUpperCase()],
                PrinterTypes: state=> state.app[(DATA.ITEMS.PRINTER_TYPES.values).toUpperCase()],
                ComputerTypes: state=> state.app[(DATA.ITEMS.COMPUTER_TYPES.values).toUpperCase()],
                ComputerUsageOption: state=> state.app["COMPUTER_USAGE_OPTION"],
            }),  
            DeviceData(){
                return this.JoinedDevices?this.JoinedDevices:this.Devices
            },
            Computers(){
                let devices = this.DeviceData 
                if(!devices){return null}
                let Computers = devices.filter(item=>{
                    return this.MBS.actions.TEXT_UP(item.device_type)
                     == this.MBS.actions.TEXT_UP(DATA.ITEMS.COMPUTERS.value)
                }) 
                return Computers
            },
            Selectors(){ 
                let ComputerTypes = this.ComputerTypes 
                let PrinterTypes = this.PrinterTypes 
                let ComputerUsageOption = this.ComputerUsageOption 
                return{
                    ComputerTypes: ComputerTypes, 
                    PrinterTypes: PrinterTypes, 
                    ComputerUsageOption: ComputerUsageOption, 
                }
            },
            route(){
                return this.$route
            },
            vs(){
                return this.$vuetify.breakpoint
            },  
            MyLinks(){  
                let thisLinks = this.MBS.actions.FIND_SUB_LINKS("devices",2,true) 
                return thisLinks
            }, 
            PATH_ITEM(){  
                let path = this.MBS.actions.COMPANY_LINK(DATA.PATHS.devices,true) 
                return path
            }, 
            HEADER_ITEMS(){
                return [ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"",value:'icon_image', size:35,tile:true,show:true},
                    // {id:0,name:"",value:'icon_image',icon:"mdi-desktop-classic",size:35,tile:true,show:true},
                    {id:0,name:"Number",value:'no',show:false},   
                    {id:0,name:"code",align:"center",show:true},     
                    {id:0,name:"Serial",value:"serial_number",show:false},       
                    {id:0,name:"IP Address",value:"network_ip_address",show:true},       
                    {id:0,name:"Brand",value:"brand_name",show:true},       
                    {id:0,name:"Name",value:"name",show:true},       
                    {id:0,name:"Usage",value:"computer_usage_option_name",show:true},       
                    {id:0,name:"description",value:'description',show:false},    
                    {id:0,name:"create",value:'created_at_',show:false},    
                    {id:0,name:"update",value:'updated_at_',show:false},   
                    {id:0,name:"action",align:"right",show:true,item:[ 
                        {name:"Edit "+this.NAME_ITEM,action:"edit_item",icon:"edit"}, 
                        {name:"Archive "+this.NAME_ITEM,action:"archive_item",icon:"mdi-delete"}, 
                    ]}, 
                ]
            },
            HEADER_ITEMS2(){
                return [ 
                    {id:1,name:"General",title:true}, 
                    {id:0,name:"Number",value:'no',show:true},  
                    {id:0,name:"name",show:true}, 
                    {id:0,name:"code",align:"center",show:true},      
                    {id:0,name:"action",align:"right",show:true,item:[  
                        {name:"Edit "+this.NAME_ITEM,action:"edit_item",icon:"mdi-pencil"},
                        {name:"Delete "+this.NAME_ITEM,action:"delete_item",icon:"mdi-delete"},
                    ]}, 
                ]
            },  
        },
        methods: { 
            async testPrinter(){
                // var prams = JSON.stringify({
                //     "comPort": "COM14",
                //     "baudRate": 115200,
                //     "products": [
                //         {
                //         "productName": "Sugor",
                //         "taxGroup": "A",
                //         "singlePrice": "1",
                //         "quantity": "1"
                //         }
                //     ]
                // });
                // let url = 'http://localhost:5000/api/Receipt/PrintReceipt'
                // let res = await this.$http.post(url,prams)
                // let body = res?res.body:null
                // console.log(res,"res...");
                // console.log(body,"body...");
                var data = JSON.stringify({
                    "comPort": "COM14",
                    "baudRate": 115200,
                    "products": [
                        {
                        "productName": "Bread",
                        "taxGroup": "A",
                        "singlePrice": "1.5",
                        "quantity": "1"
                        },{
                        "productName": "Cold Drink",
                        "taxGroup": "A",
                        "singlePrice": "0.5",
                        "quantity": "1"
                        }
                    ]
                    });

                    var config = {
                    method: 'post',
                    url: 'http://localhost:5000/api/Receipt/PrintReceipt',
                    headers: { 
                        'Content-Type': 'application/json'
                    },
                    data : data
                    };

                    this.axios(config)
                    .then(function (response) {
                    console.log(JSON.stringify(response.data));
                    })
                    .catch(function (error) {
                    console.log(error);
                    });
            },
            TABLE_ACTION(action,item){
                try {
                    let route = this.route
                    let fullPath = route?route.fullPath:null 
                    if (action == "order_stock") {
                        this.MBS.actions.go(fullPath+"/order")
                    }else if (action == "received_stock") {
                        this.MBS.actions.go(fullPath+"/receive")
                    }else if (action == "return_stock") {
                        this.MBS.actions.go(fullPath+"/return")
                    }else if (action == "transfer_stock") {
                        this.MBS.actions.go(fullPath+"/transfer")
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'TABLE_ACTION',
                        page:PAGE_NAME, 
                    }) 
                }
                
            }, 
            //---------------[MAIN FUNCTIONS]------------ 
            DIALOG_YES(action){ 
                try {
                    if (action.code) {
                        if (action.code ===PAGE_NAME+'=ADD-ITEM') {
                            if (action.YES) {
                                if (this.input.check) { 
                                    this.input.check = false 
                                    setTimeout(() => {
                                        this.input.loading = true 
                                        this.$store.dispatch("fi_add",{
                                            action:this.VALUE_ITEM,
                                            path:this.PATH_ITEM,
                                            data:action.data,
                                            us:this.us
                                        })
                                    }, ACTION_TIME);  
                                }   
                            } else {
                                
                            }
                        }  
                    } 
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'DIALOG_YES',
                        page:PAGE_NAME, 
                    }) 
                }  
            },  
            FEEDBACKS(time){  
                try {
                    if (!time) { time=400 }
                    setTimeout(()=>{
                        let us = this.loggedUser 
                        let ps = this.processes
                        let ld = this.loading
                        let res = this.responses

                        //Check process 
                        if (ps) {
                            let gettingItems = ps.find(process=>{
                                return process.name == 'getting/'+this.VALUE_ITEMS
                            }) 
                             
                        }else{
                            this.PAGE_LOAD =true
                        } 
                    },time)
                } catch (error) {
                    this.MBS.actions.error({
                        error:error,
                        from:'FEEDBACKS',
                        page:PAGE_NAME, 
                    })  
                } 
            },  
        },
        watch: {  
            loading:{ 
                handler:"FEEDBACKS",
                immediate:true
            } ,
            processes:{ 
                handler:"FEEDBACKS",
                immediate:true
            },
            responses:{ 
                handler:"FEEDBACKS",
                immediate:true
            } 
        },
    }
</script>
 
